import React, { useState, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap-v5';
// import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
// import Slider from "./Slider";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function GetInTouch(props) {
  const [varified, setVarified] = useState(false);
  function onChange(value) {
    console.log('Captcha value:', value);
    setVarified(true);
  }
  const [contact, setContact] = useState({
    email: '',
    firstname: '',
    lastname: '',
    // Add other contact properties as needed
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleAddContact = async () => {
    try {
      const pat = 'pat-eu1-9f50c1e6-45dd-4569-a5e7-35f7699d7740';
      const apikey = 'ac625a74-cb2f-4021-b04d-293d58b76618'
      const apiUrl = 'https://api.hubapi.com/contacts/v1/contact';

      // Customize the payload with the contact information
      const payload = {
        properties: [
          { property: 'email', value: contact.email },
          { property: 'firstname', value: contact.firstname },
          { property: 'lastname', value: contact.lastname },
          // Add other contact properties as needed
        ],
      };
        // const response = await axios.post(apiUrl, payload, {
        //   params: {
        //     'hapikey' : apikey
        //   },
        //   headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': `Bearer ${pat}`
        //   },
        // });

        axios.get('https://api.hubapi.com/crm/v3/objects/contacts',
        {
          headers: {
            'Authorization': `Bearer ${pat}`,
            'Content-Type': 'application/json'
          }
        },
        (err, data) => {
          console.log('Contact added successfully:', data);
        }
      );

      console.log('Contact added successfully:');

      // Optionally, you can reset the form or perform other actions after successful addition
      setContact({
        email: '',
        firstname: '',
        lastname: '',
        // Reset other contact properties as needed
      });
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };
  const [projectType, setProjectType] = useState('');
  const [formErrors, setFormErrrors] = useState({});
  const [errMsg, setErrMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [project, setProject] = useState({
    firstName: '',
    lastName: '',
    homePhone: '',
    email: '',
    companyName: '',
    estimatedBudget: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProject((project) => {
      return { ...project, [name]: value };
    });
  };

  const hadleSubmit = async (e) => {
    e.preventDefault();
    setFormErrrors(validate(project));
    validateProjectType();
    const {
      firstName,
      lastName,
      homePhone,
      email,
      companyName,
      estimatedBudget,
    } = project;

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/services/user/projects`,
        {
          firstName,
          lastName,
          homePhone,
          email,
          companyName,
          estimatedBudget,
          projectType,
        }
      );
      console.log(response.data.status);
      if (response.status === 201) {
        setErrorMsg('');
      }
    } catch (error) {
      setErrorMsg(error.response.data.message);
    }
  };
  const validate = (values) => {
    const regex =
      /^[A-Za-z]{1,}[A_Za-z_.0-9]{3,}@[A-Za-z]{3,}[.]{1}[A-Za-z.]{2,6}$/;
    const phone_regex = /^\+?[(]?[0-9]{3}[)]?[- .]?[0-9]{3}[- .]?[0-9]{4,6}$/im;
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'firstName is required';
    }
    if (!values.lastName) {
      errors.lastName = 'lastName is required';
    }
    if (!values.homePhone) {
      errors.homePhone = 'homePhone is required';
    } else if (!phone_regex.test(values.homePhone)) {
      errors.homePhone = 'please enter a valid mobile number';
    }
    if (!values.email) {
      errors.email = 'email is required';
    } else if (!regex.test(values.email)) {
      errors.email = 'please enter a valid email';
    }
    if (!values.estimatedBudget) {
      errors.estimatedBudget = 'estimatedBudget is required';
    } else if (values.estimatedBudget === '0') {
      errors.estimatedBudget = 'EstimatedBudget should be  greater than 0';
    }
    if (!values.companyName) {
      errors.companyName = 'companyName is required';
    }

    return errors;
  };

  const validateProjectType = () => {
    if (projectType.length === 0) {
      setErrMsg(true);
    } else {
      setErrMsg(false);
    }
  };
  const ref = useRef(null);
  return (
    <div ref={ref} id="GetInTouch" className='mxr__project mxr__section'>
      <Container>
        <form onSubmit={hadleSubmit}>
          <h3 className='mxr__section-title'>Join Us</h3>
          <div>
            {errorMsg ? (
              <p
                style={{
                  fontSize: '1rem',
                  color: '#e0284f',
                  letterSpacing: '2px',
                  textTransform: 'capitalize',
                }}
              >
                {errorMsg}
              </p>
            ) : null}
          </div>
          <div className='inputfild'>
            <Row>
              <Col className='text-white'>
                <p className='text-white'>
                  Embark on a thrilling journey into the past and become part of
                  the ever-growing DinoWorld community. Whether you're a
                  passionate dinosaur enthusiast, an avid reader, or an explorer
                  at heart, there's a place for you in our vibrant community.
                </p>
                <h5>Why Join?</h5>
                <ul>
                  <li>
                    <strong>Exclusive Updates:</strong> Be the first to know
                    about new releases, exciting features, and upcoming events
                    in the world of DinoWorld.
                  </li>
                  <li>
                    <strong>Access Special Content:</strong> Enjoy exclusive
                    content, behind-the-scenes glimpses, and participate in
                    community challenges and contests.
                  </li>
                </ul>{' '}
                <h5>How to join?</h5>
                <ul>
                  <li>
                    <strong>Subscribe:</strong> Enter your email to receive our
                    newsletter and stay updated on all things DinoWorld.
                  </li>
                  <li>
                    <strong>Follow Us:</strong> Connect with us on social media
                    platforms for real-time updates, community highlights, and
                    more.
                  </li>
                </ul>{' '}
              </Col>
              <Col className=' formCol'>
                {
                  <label style={{ color: '#e0284f' }}>
                    {formErrors.firstName}
                  </label>
                }
                <input
                  type='text'
                  placeholder='First name*'
                  value={contact.firstname}
                  onChange={handleInputChange}
                  name='firstname'
                />
                <label style={{ color: '#e0284f' }}>
                  {formErrors.lastName}
                </label>
                <input
                  type='text'
                  placeholder='Last name*'
                  value={contact.lastname}
                  onChange={handleInputChange}
                  name='lastname'
                />
                <label style={{ color: '#e0284f' }}>{formErrors.email}</label>
                <input
                  type='email'
                  placeholder='Email'
                  value={contact.email}
                  onChange={handleInputChange}
                  name='email'
                />
                <ReCAPTCHA
                  sitekey='6LebgowhAAAAAK6TU17d-_upiomgLDG4fEbGNwDe'
                  onChange={onChange}
                />
                <button type='button' onClick={handleAddContact}>
                  Join
                </button>
              </Col>
            </Row>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default GetInTouch;
