import React from 'react';
import './features.css';
import { Container, Card, Col, Row } from 'react-bootstrap-v5';
import webdesingn from '../../assets/icons/features/webdesingn.png';
import mobile from '../../assets/icons/features/mobile.png';
import vr from '../../assets/icons/features/vr.png';
import augumented from '../../assets/icons/features/augumented.png';
import virtual from '../../assets/icons/features/virtual.png';
import mixed from '../../assets/icons/features/mixed.png';
// import Immersive from '../../assets/icons/features/Immersive.png';
// import AI from '../../assets/icons/features/AI.png';

import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';

const initialCards = [
  {
    title: 'AUGMENTED REALITY',
    imgSrc: augumented,
    description: `Meta Extended Reality (MXR) believes and promises to deliver the best services in regards to auditory effects, visual effects, sensory requirements and all that comes under AR.`,
    linkTo: '/augmented-reality',
  },

  {
    title: 'VIRTUAL REALITY',
    imgSrc: virtual,
    description: `MXR provides its users a whole new world and experience to the actual VR technology and application. Turning your dream environment and imaginations into virtual reality is what we do at MXR.`,
    linkTo: '/virtual-reality',
  },
  {
    title: 'MIXED REALITY',
    imgSrc: mixed,
    description: `As the name suggests, MR is the blending of physical and virtual environments to create new digitized environment and visualizations.`,
    linkTo: '/mixed-reality',
  },
  // {
  //   title: 'IMMERSIVE ROOMS',
  //   imgSrc: Immersive,
  //   description: `Our immersive rooms are specially designed spaces where digital and physical worlds converge to create high-fidelity simulations.`,
  //   linkTo: '/mixed-reality',
  // },
  // {
  //   title: 'ARTIFICIAL INTELLIGENCE',
  //   imgSrc: AI,
  //   description: `MXR offers adaptable and scalable AI solutions that enable businesses to boost productivity, make data-driven choices and stay ahead in the rapidly evolving technological landscape. `,
  //   linkTo: '/mixed-reality',
  // },
  // {
  //   title: 'DIGITAL TWINNING',

  //   imgSrc: mixed,
  //   description: `With the help of our digital twinning services, businesses can track, evaluate, and optimize their operations in real-time by generating virtual copies of their real assets, systems, or procedures. `,
  //   linkTo: '/mixed-reality',
  // },
  {
    title: 'WEB DESIGN AND DEVELOPMENT',
    imgSrc: webdesingn,
    description: `Our immersive web development ideas are all what a user would want in his website. When it comes to design a website should look attractive, theme based and should be easy to use.`,
    linkTo: '/web-design-and-development',
  },
  {
    title: 'MOBILE APP DEVELOPMENT',
    imgSrc: mobile,
    description: `MXR team thrives to create best software applications in the form of mobile apps which cover all the requirements of client side.`,
    linkTo: '/mobile-app-development',
  },
  {
    title: 'VR 360',
    imgSrc: vr,
    description: `Virtual reality (VR) is a simulated experience that can be similar to or completely different from the real world. Applications of virtual reality include entertainment and education`,
    linkTo: '/vr-360',
  },
  // Add more card data as needed
];

const Features = () => {
  //const [visibleCards, setVisibleCards] = useState(initialCards.slice(0, 6));
  //const [allCardsLoaded, setAllCardsLoaded] = useState(false);

  // const loadMore = () => {
  //   const startIndex = visibleCards.length;
  //   const endIndex = startIndex + 4;
  //   const nextCards = initialCards.slice(startIndex, endIndex);
  //   setVisibleCards([...visibleCards, ...nextCards]);

  //   // Check if there are more cards to load
  //   if (endIndex >= initialCards.length) {
  //     setAllCardsLoaded(true);
  //   }
  // };

  return (
    <div className='mxr__features mxr__section'>
      <Container>
        <h3 className='mxr__section-title'>WHAT WE CAN HELP YOU WITH</h3>

        <Row>
          {initialCards.map((card, index) => (
            <Col key={index} sm={12} md={4} lg={4}>
              <Card>
                <img src={card.imgSrc} alt={card.title} />
                <Card.Title>{card.title}</Card.Title>
                <Card.Body>
                  <Card.Text>{card.description}</Card.Text>
                </Card.Body>
                <Link to={card.linkTo} className='features_text_color'>
                  Explore <IoIosArrowForward />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>

        {/* {!allCardsLoaded && (
          <div className='d-flex justify-content-center'>
            <button className='mxr__section-btn' onClick={loadMore}>
              Load More
            </button>
          </div>
        )} */}
      </Container>
    </div>
  );
};

export default Features;
