import React from 'react';

import Process from '../../components/process/Process';
import images from '../../assets/icons/benifits/ai.png';

import Technology from '../AR/Technology';
import { Innerbanner, Readytotalk } from '../../components';
import Benifits from '../../Pages/vr360/Benifits';
import Project from '../project/Project';
import { Helmet } from 'react-helmet';

const dataList = [
  {
    id: '1',
    heading: 'Our mission is to accelerate the development of AI',
    images: (
      <img
        src={images}
        alt='benifits of vr 360'
        style={{ width: '380px', height: '380px' }}
      />
    ),
    pharagraphs: [
      'Innovative technology company that specializes in developing and implementing artificial intelligence solutions for various industries.They provide advanced machine learning algorithms and data analytics tools to help businesses optimize their operations, increase efficiency, and enhance customer experience. Their expertise ranges from natural language processing and computer vision to predictive analytics',
    ],
    features: [
      'Develop custom AI solutions.',
      'Implement AI technologies.',
      'Provide AI consulting services.',
    ],
  },
];

const bannerdata = {
  img: 'vr-360-banner.jpg',
  title: 'Artificial intelligence will help everyone succeed.',
  text: 'Analysis And Deep Machine Learning Provider With Ai Solution',
};

const readytotalk = {
  img: 'about-banner.jpg',
  title: 'READY TO DISCUSS YOUR PROJECT?',
  text: 'We would love to hear about your idea and make it happen. You come to us with your idea, and we do all of the planning and costing for you.',
  button: 'CONTACT US',
};

const formdata = [
  {
    heading: <h6>Project Type *</h6>,
    labels: [
      'Tourism and culture AR apps',
      'Real estate AR apps',
      'Training and education AR apps',
      'Retail AR apps',
      'Healthcare AR apps',
    ],
  },
];

function Ai() {
  return (
    <div>
      <Helmet>
        <title>AI and Machine Learning Products Creators | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <Benifits data={dataList} />

      {/* <Capabilities /> */}
      <Process title='OUR SOFTWARE DEVELOPMENT PROCESS' />
      <Readytotalk discuss={readytotalk} />
      <Technology />
      <Project form={formdata} />
    </div>
  );
}

export default Ai;
