import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';

const Features = () => {
  return (
    <div className='mxr__capabilities mxr__section bg-light'>
      <Container>
        <h3 className='mxr__section-title_black text-center mb-5'>
          REVIEW VIDEO
        </h3>

        <Row>
          <Col className='text-center'>
            <p>
              <Row>
                <Col>
                  <p>
                    "DinoWorld" is not just a book; it's a gateway to a
                    prehistoric realm brought to life through cutting-edge AR
                    technology. Whether you're a dinosaur enthusiast, a young
                    explorer, or someone fascinated by the wonders of the past,
                    "DinoWorld" promises an immersive and educational adventure
                    that will leave readers in awe of the magnificent creatures
                    that once roamed our planet. Get ready to embark on an
                    unforgettable journey into the heart of the Mesozoic Era!
                  </p>
                </Col>
              </Row>
            </p>
            <video width='1000' controls>
              <source src='mov_bbb.mp4' type='video/mp4' />
              <source src='mov_bbb.ogg' type='video/ogg' />
              Your browser does not support HTML video.
            </video>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
