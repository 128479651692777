export const menuItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'About',
    link: '/about',
  },
  {
    title: 'Services',
    submenu: [
      {
        title: 'Augmented Reality',
        link: '/augmented-reality',
      },
      {
        title: 'Virtual Reality',
        link: '/virtual-reality',
      },
      {
        title: 'Mixed Reality',
        link: '/mixed-reality',
      },
      // {
      //   title: 'Immersive rooms',
      //   link: '/immersive_room',
      // },

      // {
      //   title: 'Artificial Intelligence',
      //   link: '/ai-machine-learning',
      // },

      // {
      //   title: 'Digitial Twinning',
      //   link: '/digitial_twinning',
      // },

      {
        title: 'VR 360',
        link: '/vr-360',
      },

      {
        title: 'Web Design and Development',
        link: '/web-design-and-development',
      },

      {
        title: 'Research and Innovation',
        link: '/research-and-innovation',
      },
      {
        title: 'Mobile App Development',
        link: '/mobile-app-development',
      },
    ],
  },
  {
    title: 'Technologies',
    link: '/technology',
    megamenu: 'yes',
    menusections: [
      {
        sectiontitle: 'Server-Side',
        submenu: [
          {
            title: 'Php',
            link: '/php',
          },
          {
            title: 'Laravel',
            link: '/laravel',
          },
          {
            title: 'CodeIgniter',
            link: '/code',
          },
          {
            title: 'Python',
            link: '/python',
          },
          {
            title: 'Node.js',
            link: '/node',
          },
        ],
      },
      {
        sectiontitle: 'Frontend',
        submenu: [
          {
            title: 'Angular',
            link: '/angular',
          },
          {
            title: 'React.js',
            link: '/reactjs',
          },
          {
            title: 'Vue.js',
            link: '/vue',
          },
          {
            title: 'MEAN Stack',
            link: '/mean',
          },
          {
            title: 'MERN Stack',
            link: '/mern',
          },
        ],
      },
      {
        sectiontitle: 'E-Commerce',
        submenu: [
          {
            title: 'Magento',
            link: '/magento',
          },
          {
            title: 'WooCommerce',
            link: '/commerce',
          },
          {
            title: 'Shopify',
            link: '/shopify',
          },
        ],
      },
      {
        sectiontitle: 'Mobile',
        submenu: [
          {
            title: 'iOS',
            link: '/ios',
          },
          {
            title: 'Android',
            link: '/android',
          },
          {
            title: 'React Native',
            link: '/reactnative',
          },
          {
            title: 'Hybrid',
            link: '/hybrid',
          },
          {
            title: 'Flutter',
            link: '/flutter',
          },
          {
            title: 'Ionic',
            link: '/ionic',
          },
          {
            title: 'Swift',
            link: '/swift',
          },
        ],
      },
      {
        sectiontitle: 'CMS',
        submenu: [
          {
            title: 'WordPress',
            link: '/worldpress',
          },
          {
            title: 'Drupal',
            link: '/drupal',
          },
          {
            title: 'Shopify',
            link: '/shopify',
          },
          {
            title: 'Magento',
            link: '/magento',
          },
        ],
      },
      {
        sectiontitle: 'AR/VR/MR',
        submenu: [
          {
            title: 'Custom Unreal Engine',
            link: '/unreal-engine',
          },
          {
            title: 'Unity App Development',
            link: '/unity',
          },
        ],
      },
    ],
  },
  {
    title: 'Projects',
    link: '/projects',
  },
  {
    title: 'Blog',
    link: '/blog',
  },
  {
    title: 'Contact',
    link: '/contact',
  },
];
