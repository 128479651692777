import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap-v5';

const CTA = () => {

  const handleClick = () => {
    var element = document.getElementById("GetInTouch");
    element.scrollIntoView({behavior:"smooth", block: "center", inline:"nearest"});
  };
  return (
    <div className='mxr__Cta mxr__section'>
      <Container>
        <Row>
          <Col md={8} className='text-center'>
            <h5 className='mxr__cta-heading'>
              Uncover the Secrets - Start Your DinoWorld Journey!
            </h5>
          </Col>
          <Col md={4} className='text-center'>
            <Button onClick={handleClick}  className='mxr__cta-button'>Join Us</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CTA;
