import React from 'react';
import images from '../../assets/icons/benifits/ar-benifits.png';
import { Innerbanner2, Readytotalk } from '../../components';
import Project from '../project/Project';
import { Helmet } from 'react-helmet';
import Features from './DinoWorld/Features';
import VideoReview from './DinoWorld/VideoReview';
import HowItHappens from './DinoWorld/HowItHappens';
import GetInTouch from './DinoWorld/GetInTouch';
import Faqs from './DinoWorld/FAQ';
import CTA from './DinoWorld/CTA';

const bannerdata = {
  img: 'dinoBanner.png',
  title: 'Journey Through Time with DinoWorld: Where Books Come to Life!',
  text: 'Interactive Learning meets Prehistoric Wonder in DinoWorld!',
  button: 'Join Us',
};

const readytotalk = {
  img: 'about-banner.jpg',
  title: 'READY TO DISCUSS YOUR PROJECT?',
  text: 'We would love to hear about your idea and make it happen. You come to us with your idea, and we do all of the planning and costing for you.',
  button: 'CONTACT US',
};

function DinoWorld() {
  return (
    <div>
      <Helmet>
        <title>DinoWorld - Unleash the Dinosaurs| MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Innerbanner2 banner={bannerdata} />
      <Features />
      <VideoReview />
      <CTA />
      <HowItHappens />
      <Faqs />
      <GetInTouch />
    </div>
  );
}

export default DinoWorld;
