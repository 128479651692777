import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import step3img from './../../../assets/images/DinoWorld/step3.png';
import step1img from './../../../assets/images/DinoWorld/step1.png';
import step2img from './../../../assets/images/DinoWorld/step2.png';
import step4img from './../../../assets/images/DinoWorld/step4.png';
import { FaDesktop, FaImage, FaTv, FaSun } from 'react-icons/fa';

const Features = () => {
  return (
    <div className='mxr__capabilities mxr__section white_bg_point'>
      <Container>
        <h3 className='mxr__section-title_black text-center mb-5'>
          BOOK FEATURES
        </h3>
        <Row>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
            <img src={step3img} alt='test_image' />
              {/* <FaDesktop size={30} /> */}
            </div>
            <div>
              <h5>Meet the Titans of the Past:</h5>
              <p>
                Embark on a journey to encounter the most iconic dinosaurs that
                once ruled the Earth. From the majestic Tyrannosaurus Rex to the
                graceful Triceratops, "DinoWorld" introduces readers to a
                diverse array of ancient creatures. Through AR, each dinosaur is
                brought to life with stunning details, realistic movements, and
                fascinating behaviors, offering an educational and thrilling
                experience for readers of all ages.
              </p>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
            <img src={step1img} alt='test_image' />
              {/* <FaImage size={30} /> */}
            </div>
            <div>
              <h5>Interactive Learning Adventures:</h5>
              <p>
                "DinoWorld" goes beyond traditional storytelling by integrating
                educational elements seamlessly into the narrative. Readers can
                engage in interactive activities, such as feeding herbivores,
                observing carnivores in their natural habitat, and even
                participating in a dino-themed quiz. Learning about paleontology
                has never been this exciting!
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
            <img src={step2img} alt='test_image' />
              {/* <FaTv size={30} /> */}
            </div>
            <div>
              <h5>Unlock the Secrets of the Past:</h5>
              <p>
                Dive into the mysteries of paleontology and uncover the secrets
                of the past. "DinoWorld" includes interactive features that
                allow readers to excavate fossils, assemble dinosaur skeletons,
                and explore 3D models of extinct species. It's an engaging blend
                of storytelling and hands-on exploration, making learning about
                dinosaurs a thrilling adventure.
              </p>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className='icon_leftside'>
            <img src={step4img} alt='test_image' />
              {/* <FaSun size={30} /> */}
            </div>
            <div>
              <h5>Step into the Mesozoic Era:</h5>
              <p>
                Immerse yourself in the captivating world of dinosaurs like
                never before! "DinoScape" transports readers to the Mesozoic Era
                through the magic of Augmented Reality (AR), where lifelike
                dinosaurs roam and landscapes come to life. This interactive
                experience brings the past into the present, allowing readers to
                witness the wonders of prehistoric life firsthand.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
